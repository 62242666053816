.filter-box{
    width: 300px;
    height: 150vh;
    background-color: #fff;
    border-radius: 8px;
}

.search-field{
    padding: 13px 11px;
}
.search-input{
    width: 250px;
  height: 34px;
  padding: 1px 17px 0 2px;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  
}