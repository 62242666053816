.product_details {
    width: 460px;
    height: 640px;
    flex-grow: 0;
    /* margin: 0px 84px 49px 20px; */
    padding: 44px 44px 249px 45px;
    border-radius: 8px;
    box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
  }

  .MAR-DEL-PLATA-MARATHON-ARGENTINA {
    width: 362px;
    height: 53.1px;
    flex-grow: 0;
    margin: 0 0 3.9px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    text-align: left;
    color: #000;
  }

  .Photo-by-Raymond-Peters {
    font-size: 14px;
    text-align: left;
    color: #000;
    margin-bottom: 30px;
  }

  .custom-line {
    width: 355.5px;
    height: 1px;
    flex-grow: 0;
    background-color: #f0f0f0;
    margin-top: 10px;
  }

  .add-photo-print{
    border-radius : 5px;
    background : #f8f8f8;
    height : 44px;
    width : 371px;
    padding : 12px 0 12px 14px;
    font-size : 14px;
    font-weight : 600
  }
  .add-to-cart-btn{
        width: 371px;
        height: 40px;
        margin: 16px 0;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        background-color: #fd4420;
        border-radius: 4px;
  }
  .back-to-gallery-btn{
    width: 200px;
    height: 40px;
    margin: 16px 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #fd4420;
    border-radius: 4px;
}

  .share-btn{
    width: 371px;
    height: 40px;
    background-color: #c4c4c4;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
  }