.searchBar {
    height: 70px;
    flex-grow: 0;
    margin: 55px 0 0px;
    padding: 17px 85px 13px 84px;
    /* box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.15); */
    background-color: #fff;
    position: fixed;
    z-index: 900;
    width: 100%;
  }

  .searchField {
    width: 700px;
    height: 40px;
    /* margin: 0 78px 0 69px; */
    padding: 1px 16px 5px 15px;
    border-radius: 6px;
    border: solid 1px #d4d4d4;
    background-color: #fff;
  }

  input:focus{
    border: solid 1px #d4d4d4;
      outline: none
  }

  .FilterArea{
      background-color: #f0f0f0;
      min-height: 130vh;
      margin-top: 130px;
  }