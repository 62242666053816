/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400&display=swap");
*{
    font-family: Lexend;
} */

:root {
  --footer-height: 3rem;
  --navbar-width: 20.83%;
  --header-height: 3rem;
  --navbar-left-margin: 5.55%;
  
  --logo-primary-color: #FD4420;
  --light-background: #E5E5E5;
  --light-primary: #FFFFFF;

  --photo-tag-status-background-color-completed: rgba(0, 143, 14, 0.1);
  --photo-tag-status-background-color-pending: rgba(255, 153, 0, 0.1);
  --photo-tag-status-background-color-error: rgba(255, 0, 0, 0.1);
  --photo-tag-status-text-color-completed: #008F0E;
  --photo-tag-status-text-color-pending: #FF9900;
  --photo-tag-status-text-color-error: #FF0000;
  --photo-tags: #3FA2F7;
}

.outer-container {
  background-color: var(--light-background);
}