@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap");

.imgBG {
  margin: auto;
  min-height: 80vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/adobe-stock-234261927-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Hero-Text {
  width: 700px;
  height: 50px;
  flex-grow: 0;
  margin: 0 25px 4px 0;
  text-shadow: 5px 1px 24px #851702;
  font-family: Lexend;
  font-size: 40px;
  font-weight: 600;
font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Rectangle-4 {
  width: 708px;
  height: 83px;
  flex-grow: 0;
  margin: 4px 0 0;
  padding: 24px 32px 19px 33px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.5);
}

.field {
  width: 503px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 1px 18px 5px 12px;
  font-family: Lexend;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  outline: none;
}

.homebtn {
  width: 150px;
  height: 40px;
  /* margin: 0 0 0 20px; */
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #fd4420;
  border-radius: 4px;
}


.optionarea{
  min-height: 100px;
  width: 480px;
  margin-top: 196px;
  background-color: #fff;
  margin-right:161px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: column;
}