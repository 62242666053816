table {
    font-family: arial, sans-serif;
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 0 0 5px 5px;
  }
  
  tr {
    border-top: 1px solid #DEDEDE;
  }

  th {
      font-weight: 600;
  }
  
  td {
      font-weight: 400;
      color: #676767;
      border: none;
      text-align: left;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-family: 'Lexend';
      font-style: normal;
      font-size: 12px;
      line-height: 0px;
  }
  
  .table-header {
      background-color: white;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
  }
  
  .table-header:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .table-header-section {
    float: left;
    width: 33.33%;
  }
  
  .table-header-section:nth-of-type(3n + 1) {
      font-style: normal;
      font-family: 'Lexend';
      font-weight: 500;
      font-size: 1rem;
      padding-left: 1rem;
  }
  
  .table-header-section:nth-of-type(3n + 2) {
      text-align: center;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      color: #676767;
  }
  
  .table-header-section:nth-of-type(3n) {
      text-align: center;
  }
  
  .table-header-section-button {
      border: 1px solid #C4C4C4;
      border-radius: 5px;
      height: 2rem;
      font-family: 'Lexend';
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      font-style: normal;
      background-color: var(--logo-primary-color);
    /* #FFFFFF - White */
      color: var(--light-primary);
  }
  
  span.photo-tag-status {
      padding: 4px 8px;
      gap: 8px;
      border-radius: 6px;
  }
  
  span.photo-tag-status-completed {
      background-color: var(--photo-tag-status-background-color-completed);
      color: var(--photo-tag-status-text-color-completed);
  }
  
  span.photo-tag-status-pending {
      background-color: var(--photo-tag-status-background-color-pending);
      color: var(--photo-tag-status-text-color-pending);
  }
  
  span.photo-tag-status-error {
      background-color: var(--photo-tag-status-background-color-error);
      color: var(--photo-tag-status-text-color-error);
  }
  
  span.photo-tags {
      color: var(--photo-tags);
  }
  
  .section-header {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 26px;
      margin-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
  }
  
  .table {
      width: 95%;
      margin-left: 1rem;
  }
  
  .uploadPicturesWrapper div {
      justify-content: flex-start !important;
      font-family: 'Noto Sans JP', sans-serif;
  }
  
  .uploadPictureContainer {
      width: 200px !important;
      height: auto !important;
      margin: 2% 5% !important;
      padding: 10px !important;
  }
  
  .uploadPictureContainer img {
      height: 200px !important;
  }
  
  .butt1{
      color:white;
      background-color: #002B5B !important;
      font-family: 'Noto Sans JP', sans-serif;
      height: 40px;
  }  

  .def-button {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    /* position: absolute;
    right: 15%;
    top: 11.64%; */
    text-align: center;
    padding: 0.8rem 1.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    background-color: var(--logo-primary-color);
    /* #FFFFFF - White */
    color: var(--light-primary);
  }
  