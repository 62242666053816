.page {
    position: absolute;
    right: 0;
    top: var(--header-height);
    width: calc(100% - var(--navbar-width) - var(--navbar-left-margin));
    height: calc(100% - var(--header-height) - var(--footer-height));
    overflow: auto;
    padding: 0.5rem;
}

/* Chrome, Edge, and Safari */
.page::-webkit-scrollbar {
    width: 9px;
}

.page::-webkit-scrollbar-track {
    background: #ebebeb;
}

.page::-webkit-scrollbar-thumb {
    background-color: #fd4420;
    border-radius: 2px;
    border: 13px none #ffffff;
}

.page::-webkit-scrollbar:hover {
    cursor: pointer;
}