/* .navbar {
  width: var(--navbar-width);
  position: relative;
  margin-left: var(--navbar-left-margin);
  background-color: var(--light-primary);
  height: 100%;
} 

.links-container {
  position: absolute;
  top: 20.77%;
  right: 23.77%;
  font-size: 12px;
  text-align: right;
} 

header {
  position: absolute;
  right: 23.77%;
  top: 4.56%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.035em;
  color: var(--logo-primary-color);
} 

.logo {
  height: 25px;
  width: 25px;
  display: inline;
  margin-right: 11.39px;
}

.create-event-button {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  position: absolute;
  right: 15%;
  top: 11.64%;
  text-align: center;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  background-color: var(--logo-primary-color);
  color: var(--light-primary);
}

.upgrade-account-button {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  position: absolute;
  right: 15%;
  top: 65.64%;
  text-align: center;
  padding: 0.8rem 1rem;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .upgrade-account-button {
    width: auto;
    white-space : wrap;
  }
}

.link {
  font-family: 'Lexend';
  font-size: 11px;
  margin-bottom: 6%;
  white-space : nowrap;
  text-align: right;
  right: 10%;
  color: grey;
}

.link:hover, .support-link:hover {
  color: var(--logo-primary-color);
}

.link-section-header {
  font-weight: bold;
  margin-top: 2%;
}

.account-section, .events-section {
  margin-top: 1rem;
}

.support-links-container {
  position: absolute;
  top: 75.77%;
  right: 23.77%;
  font-size: 12px;
  text-align: right;
}

.support-link {
  font-family: 'Lexend';
  font-size: 11px;
  margin-bottom: 6%;
  text-align: right;
  right: 10%;
  color: grey;
} */

.navbar {
  width: var(--navbar-width);
  position: relative;
  margin-left: var(--navbar-left-margin);
  background-color: var(--light-primary);
  height: 100%;
  overflow: hidden;
}

.navbar-header {
  text-align: center;
  width: 100%;
  height: 4rem;
  white-space : nowrap;
}

.outer-navbar-container {
  position: relative;
  background-color: var(--light-primary);
  /* min-height: calc(100% - var(--footer-height) - 5rem); */
  max-height: calc(100% - var(--footer-height) - 3.2rem);
  overflow: auto;
}

.inner-navbar-container {
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: var(--light-primary);
  height: 100%;
}

.outer-navbar-container::-webkit-scrollbar {
    width: 9px;
}

.outer-navbar-container::-webkit-scrollbar-track {
    background: white;
}

.outer-navbar-container::-webkit-scrollbar-thumb {
    background-color: #fd4420;
    border-radius: 2px;
    border: 13px none #ffffff;
}

.outer-navbar-container::-webkit-scrollbar:hover {
    cursor: pointer;
}

.navbar-link {
  margin-bottom: 0.5rem;
  font-family: 'Lexend';
  font-size: 11px;
  white-space : nowrap;
  text-align: center;
  color: grey;
}

.links-container {
  /* position: absolute;
  top: 20.77%;
  right: 23.77%; */
  font-size: 12px;
  text-align: right;
}

header {
  /* position: absolute;
  right: 23.77%;
  top: 4.56%; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.035em;
  color: var(--logo-primary-color);
}

.logo {
  height: 25px;
  width: 25px;
  display: inline;
  margin-right: 11.39px;
}

.create-event-button {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  /* position: absolute;
  right: 15%;
  top: 11.64%; */
  text-align: center;
  padding: 0.8rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: var(--logo-primary-color);
  /* #FFFFFF - White */
  color: var(--light-primary);
}

.upgrade-account-button {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  /* position: absolute;
  right: 15%;
  top: 65.64%; */
  text-align: center;
  padding: 0.8rem 1rem;
  border: 1px solid grey;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: white;
  /* #FFFFFF - White */
  color: black;
}

@media (max-width: 768px) {
  .upgrade-account-button {
    width: auto;
    white-space : wrap;
  }
}

.navbar-link:hover, .navbar-link-active {
  color: var(--logo-primary-color);
}

.link-section-header {
  font-weight: bold;
  margin-top: 2%;
}

.account-section, .events-section {
  margin-top: 1rem;
}

.support-links-container {
  /* position: absolute;
  top: 75.77%;
  right: 23.77%; */
  font-size: 12px;
  text-align: right;
}

.support-link {
  font-family: 'Lexend';
  font-size: 11px;
  margin-bottom: 6%;
  text-align: right;
  right: 10%;
  color: grey;
}