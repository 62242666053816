.cartBg {
  background-color: #f0f0f0;
  min-height: 140vh;
  padding: 80px 100px;
}

.Items-Details {
  width: 45vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding: 15px 20px 30px;
  border-radius: 8px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin-top: 19px;
}

.Rectangle-33 {
  min-width: 30vw;
  min-height: 428px;
  flex-grow: 0;
  margin: 19px 84px 655px 20px;
  padding: 24px 35.5px 14.8px 35.5px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.12);
}

.cartItem {
  height: 170px;
  flex-grow: 0;
  padding: 10px;
}

.increase-decrease-div {
    width: 106px;
    height: 34px;
    margin: 0 20px;
    padding: 5px 15.2px 5px 15px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

 .buy-btn {
    height: 40px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin: 20px auto;
    color: #fff;
    background-color: #fd4420;
  }

  .back-btn {
    height: 40px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin: 20px auto;
    border: solid 1px #c4c4c4;
    background: #fff;
    font-size: 15px;
    font-weight: 500;
  }
