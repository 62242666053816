@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap");

.imgBG {
  margin: auto;
  min-height: 80vh;
  width: 95%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../assets/adobe-stock-234261927-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
}

.Hero-Text {
  width: 700px;
  height: 50px;
  flex-grow: 0;
  margin: 0 25px 4px 0;
  text-shadow: 5px 1px 24px #851702;
  font-family: Lexend;
  font-size: 40px;
  font-weight: 600;
font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Rectangle-4 {
  width: 747px;
  display: flex;
  flex-direction: column;
  height: 161px;
  flex-grow: 0;
  padding: 14px 19px 28px 18px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.49);
}

.field {
  width: 503px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 1px 18px 5px 12px;
  font-family: Lexend;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  background-color: #fff;
  outline: none;
}

.homebtn {
  width: 150px;
  height: 40px;
  /* margin: 0 0 0 20px; */
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #fd4420;
  border-radius: 4px;
}

.downloadbtn {
  width: 100px;
  height: 40px;
  /* margin: 0 0 0 20px; */
  font-family: Lexend;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #fd4420;
  border-radius: 4px;
}



.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  
}

.rwd-table tr:first-child {
  border-top: none;
  background: #fd4420;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
  font-size: 14px;
  font-weight: 500;
}

.rwd-table td {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: 5em 2em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #ebe4e2;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .5em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 2em !important;
  }
}

span.photo-tag-status {
  padding: 4px 8px;
  gap: 8px;
  border-radius: 6px;
}

span.photo-tag-status-SUCCESS {
  background-color: var(--photo-tag-status-background-color-completed);
  color: var(--photo-tag-status-text-color-completed);
}

span.photo-tag-status-PENDING {
  background-color: var(--photo-tag-status-background-color-pending);
  color: var(--photo-tag-status-text-color-pending);
}
span.photo-tag-status-FAILED {
  background-color: var(--photo-tag-status-background-color-pending);
  color: var(--photo-tag-status-text-color-pending);
}

/* THE END OF THE IMPORTANT STUFF */

/* Basic Styling */

