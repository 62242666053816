table {
  font-family: arial, sans-serif;
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 5px;
}

tr {
  border-top: 1px solid #DEDEDE;
}

th {
    font-weight: 600;
}

td {
    font-weight: 400;
    color: #676767;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

td, th {
    border: none;
    text-align: left;
    padding: 1rem;
    font-family: 'Lexend';
    font-style: normal;
    font-size: 12px;
    line-height: 0px;
}

.event-name:hover {
  color: var(--logo-primary-color);
  text-decoration: underline;
}