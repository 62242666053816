.instructions-card {
    min-height: 13.5rem;
    padding-top: 1.125rem;
    padding-left: 0.813rem;
    padding-right: 0.813rem;
    padding-bottom: 0.938rem;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
}

.instructions-card:hover {
    -webkit-box-shadow: 4px 4px 10px -3px var(--light-background);
    -moz-box-shadow: 4px 4px 10px -3px var(--light-background);
    box-shadow: 4px 4px 10px -3px var(--light-background);
    transform: translate(-2px, -2px);
    cursor: pointer;
}

.instructions-index {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.38rem;
    font-weight: 600;
    color: var(--logo-primary-color);
}

.instructions-image {
    margin-bottom: 1.046rem;
    color: var(--logo-primary-color);
}

.instructions-action {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
}

.instructions-text {
    font-size: 0.75rem;
    line-height: 0.938rem;
    font-weight: 400;
    font-family: "Lexend";
    color: #676767;
}