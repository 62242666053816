@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400&display=swap");
.desktopfooter {
    position: absolute;
    /* bottom: 0; */
    width: 100%;
    min-height: 167px;
    /* flex-grow: 0; */
    padding: 51px 38.5px 47px 85px;
    background-color: #000;
    font-family: Lexend;
    font-size: 14px;
  }