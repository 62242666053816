.create-event {
    background-color: white;
    border-radius: 8px;
    width: 100%;
    padding: 0 2rem 0.5rem 0;
}

.create-event-form {
    padding-top: 2rem;
    padding-left: 3rem;
}

.create-event-header {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 0px;
    margin-bottom: 2rem;
}

.create-event-input {
    font-size: 1rem;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 200;
    border: 1px solid #D4D4D4;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    height: 2rem;
    width: 100%;
    margin-top: 0.5rem;
}

.create-event-input-label {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #333333;
}

.upload-logo {
    width: 11rem;
    height: 11rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    background-color: var(--light-background);
}

.create-event-input:focus {
    /* border: 2px solid var(--logo-primary-color); */
    outline: 1px solid var(--logo-primary-color);;
}

.create-event-input-container {
    float: left;
    margin: 0 !important;
    width: calc((2/3)*100%);
    padding-right: 1rem;
}

.create-event-logo-upload-container {
    float: left;
    margin: 0 !important;
    width: calc((1/3)*100%);
}

#event-description {
    resize: none;
    height: 9rem;
}

.create-event-cancel-button, .create-event-submit-button {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    /* margin-top: 175%; */
    bottom: 0;
    display: inline-block;
    width: 6rem;
}

@media screen and (min-width:768px, min-height:768px) {
    .create-event-cancel-button, .create-event-submit-button {
        margin-top: 5%;
        margin-bottom: 3%;
        margin-left: 3%;
    }
    .create-event {
        height: 130%;
        padding-bottom: 2%;
    }
}

@media screen and (min-width:1024px, min-height:1024px) {
    .create-event-cancel-button, .create-event-submit-button {
        margin-top: 5%;
    }

    .create-event {
        height: 130%;
        padding-bottom: 2%;
    }
}

@media screen and (min-width:1280px, min-height:1280px) {
    .create-event-cancel-button, .create-event-submit-button {
        margin-top: 175%;
    }

    .create-event {
        height: 110%;
        padding-bottom: 2%;
    }
}

.create-event-cancel-button {
    border: 1px solid #C4C4C4;
    color: #333333;
}

.create-event-submit-button {
    background-color: var(--logo-primary-color);
    color: var(--light-primary);
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

input.create-event-input:invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.3rem #ff0000;
}

.error {
    color: red;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 0.7rem;
}