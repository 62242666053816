.pricing-info-container {
    width: 95%;
    margin-left: 1rem;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    background-color: white;
    border-radius: 5px;
}

.pricing-info-body {
    padding: 1rem;
}

.pricing-info-header {
    padding-left: 1rem;
    border-bottom: 1px solid #dedede;
    font-family: 'Lexend';
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.pricing-info-box {
    border: 1px solid #D4D4D4;
    color: #676767;
    padding: 0.7rem;
    width: 53%;
    border-radius: 8px;
    margin: 1rem auto;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.pricing-info-box:focus {
    border: 2px solid var(--logo-primary-color);
}

.pricing-add-option {
    width: 2rem;
    margin: 1rem auto;
}

.pricing-modification-button {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #3FA2F7;
}

input.pricing-details {
    font-size: 1rem;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 200;
    border: 1px solid #D4D4D4;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    height: 2rem;
    width: 100%;
    margin-bottom: 0.5rem;
}

.create-event-input-label {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #333333;
}

input.pricing-details:focus {
    /* border: 2px solid var(--logo-primary-color); */
    outline: 1px solid var(--logo-primary-color);;
}

.pricing-input-label {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.pricing-action-submit, .pricing-action-cancel {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    border-radius: 5px;
    bottom: 0;
    display: inline-block;
    width: 3.5rem;
    height: 2.5rem;
}

.pricing-action-submit {
    color: #FFFFFF;
    background-color: var(--logo-primary-color);
}

.pricing-action-cancel {
    color: #333333;
    border: 1px solid #C4C4C4;
}