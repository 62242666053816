h1 {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 40px;
    color: #000000;
}

.image-section {
    height: 26.125rem;
    margin-bottom: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.create-instructions-section {
    min-height: 17rem;
    border-radius: 8px;
    background-color: white;
    padding: 0.75rem 1.3rem;
}

.section-header {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 26px;
    margin-bottom: 0.2rem;
    padding-bottom: 0.5rem;
}